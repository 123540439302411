import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/',
      name: 'loginh',
      component: () => import('@/views/Login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/configuracao',
      name: 'configuracao',
      component: () => import('@/views/Configs/Configuracao.vue'),
      meta: {
        pageTitle: 'Configuração',
        breadcrumb: [
          {
            text: 'Configuração',
            active: true,
          },
        ],
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-processo/:id',
      name: 'editar-processo-id',
      component: () => import('@/views/Processos/Editar.vue'),
      meta: {
        pageTitle: 'Processos',
        breadcrumb: [
          {
            text: 'Processos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-processo/',
      name: 'editar-processo',
      component: () => import('@/views/Processos/Editar.vue'),
      meta: {
        pageTitle: 'Processos',
        breadcrumb: [
          {
            text: 'Processos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/detalhe-processo/:id',
      name: 'detalhe-processo',
      component: () => import('@/views/Processos/Detalhe/Detalhe.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-processo',
      name: 'listar-processo',
      component: () => import('@/views/Processos/Listar.vue'),
      meta: {
        pageTitle: 'Processos',
        breadcrumb: [
          {
            text: 'Processos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-processo-pendente',
      name: 'listar-processo-pendente',
      component: () => import('@/views/Processos/Listar/ListarPendentes.vue'),
      meta: {
        pageTitle: 'Processos',
        breadcrumb: [
          {
            text: 'Processos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-processo-faturacao',
      name: 'listar-processo-faturacao',
      component: () => import('@/views/Processos/Listar/PendentesFaturacao.vue'),
      meta: {
        pageTitle: 'Processos',
        breadcrumb: [
          {
            text: 'Processos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-contrato',
      name: 'adicionar-contrato',
      component: () => import('@/views/Contratos/Adicionar.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-contrato',
      name: 'editar-contrato',
      component: () => import('@/views/Contratos/Editar.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-contrato-id/:id',
      name: 'editar-contrato-id',
      component: () => import('@/views/Contratos/Editar.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/detalhe-contrato',
      name: 'detalhe-contrato',
      component: () => import('@/views/Contratos/Detalhe/Detalhe.vue'),
      meta: {
        pageTitle: 'Contratos',
        navActiveLink: '',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/detalhe-contrato-id/:id',
      name: 'detalhe-contrato-id',
      component: () => import('@/views/Contratos/Detalhe/Detalhe.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-contrato',
      name: 'listar-contrato',
      component: () => import('@/views/Contratos/Listar.vue'),
      meta: {
        pageTitle: 'Contratos',
        breadcrumb: [
          {
            text: 'Contratos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-cliente',
      name: 'adicionar-cliente',
      component: () => import('@/views/Clientes/Adicionar.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-cliente/:id',
      name: 'editar-cliente-id',
      component: () => import('@/views/Clientes/Editar.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-cliente',
      name: 'editar-cliente',
      component: () => import('@/views/Clientes/Editar.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-cliente',
      name: 'listar-cliente',
      component: () => import('@/views/Clientes/Listar.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-documentosContrato',
      name: 'adicionar-documentosContrato',
      component: () => import('@/views/tabelas/DocumentosContrato/Adicionar.vue'),
      meta: {
        pageTitle: 'Documentos de Contrato',
        breadcrumb: [
          {
            text: 'Documentos de Contrato',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-documentosContrato/:id',
      name: 'editar-documentosContrato-id',
      component: () => import('@/views/tabelas/DocumentosContrato/Editar.vue'),
      meta: {
        pageTitle: 'Documentos de Contrato',
        breadcrumb: [
          {
            text: 'Documentos de Contrato',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-documentosContrato',
      name: 'editar-documentosContrato',
      component: () => import('@/views/tabelas/DocumentosContrato/Editar.vue'),
      meta: {
        pageTitle: 'Documentos de Contrato',
        breadcrumb: [
          {
            text: 'Documentos de Contrato',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-documentosContrato',
      name: 'listar-documentosContrato',
      component: () => import('@/views/tabelas/DocumentosContrato/Listar.vue'),
      meta: {
        pageTitle: 'Documentos de Contrato',
        breadcrumb: [
          {
            text: 'Documentos de Contrato',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-tecnico',
      name: 'adicionar-tecnico',
      component: () => import('@/views/tabelas/Tecnicos/Adicionar.vue'),
      meta: {
        pageTitle: 'Técnico',
        breadcrumb: [
          {
            text: 'Técnico',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tecnico/:id',
      name: 'editar-tecnico-id',
      component: () => import('@/views/tabelas/Tecnicos/Editar.vue'),
      meta: {
        pageTitle: 'Técnico',
        breadcrumb: [
          {
            text: 'Técnico',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tecnico',
      name: 'editar-tecnico',
      component: () => import('@/views/tabelas/Tecnicos/Editar.vue'),
      meta: {
        pageTitle: 'Técnico',
        breadcrumb: [
          {
            text: 'Técnico',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-tecnico',
      name: 'listar-tecnico',
      component: () => import('@/views/tabelas/Tecnicos/Listar.vue'),
      meta: {
        pageTitle: 'Técnico',
        breadcrumb: [
          {
            text: 'Técnico',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-documentosProcesso',
      name: 'adicionar-documentosProcesso',
      component: () => import('@/views/tabelas/DocumentosProcesso/Adicionar.vue'),
      meta: {
        pageTitle: 'Documentos de Processo',
        breadcrumb: [
          {
            text: 'Documentos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-documentosProcesso/:id',
      name: 'editar-documentosProcesso-id',
      component: () => import('@/views/tabelas/DocumentosProcesso/Editar.vue'),
      meta: {
        pageTitle: 'Documentos de Processo',
        breadcrumb: [
          {
            text: 'Documentos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-documentosProcesso',
      name: 'editar-documentosProcesso',
      component: () => import('@/views/tabelas/DocumentosProcesso/Editar.vue'),
      meta: {
        pageTitle: 'Documentos de Processo',
        breadcrumb: [
          {
            text: 'Documentos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-documentosProcesso',
      name: 'listar-documentosProcesso',
      component: () => import('@/views/tabelas/DocumentosProcesso/Listar.vue'),
      meta: {
        pageTitle: 'Documentos de Processo',
        breadcrumb: [
          {
            text: 'Documentos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-zona',
      name: 'adicionar-zona',
      component: () => import('@/views/tabelas/Zonas/Adicionar.vue'),
      meta: {
        pageTitle: 'Zonas',
        breadcrumb: [
          {
            text: 'Zonas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-zona/id',
      name: 'editar-zona-id',
      component: () => import('@/views/tabelas/Zonas/Editar.vue'),
      meta: {
        pageTitle: 'Zonas',
        breadcrumb: [
          {
            text: 'Zonas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-zona',
      name: 'editar-zona',
      component: () => import('@/views/tabelas/Zonas/Editar.vue'),
      meta: {
        pageTitle: 'Zonas',
        breadcrumb: [
          {
            text: 'Zonas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-zona',
      name: 'listar-zona',
      component: () => import('@/views/tabelas/Zonas/Listar.vue'),
      meta: {
        pageTitle: 'Zonas',
        breadcrumb: [
          {
            text: 'Zonas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-tiposIntervencao',
      name: 'adicionar-tiposIntervencao',
      component: () => import('@/views/tabelas/TiposIntervencao/Adicionar.vue'),
      meta: {
        pageTitle: 'Tipos de Intervenção',
        breadcrumb: [
          {
            text: 'Tipos de Intervenção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tiposIntervencao/:id',
      name: 'editar-tiposIntervencao-id',
      component: () => import('@/views/tabelas/TiposIntervencao/Editar.vue'),
      meta: {
        pageTitle: 'Tipos de Intervenção',
        breadcrumb: [
          {
            text: 'Tipos de Intervenção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tiposIntervencao',
      name: 'editar-tiposIntervencao',
      component: () => import('@/views/tabelas/TiposIntervencao/Editar.vue'),
      meta: {
        pageTitle: 'Tipos de Intervenção',
        breadcrumb: [
          {
            text: 'Tipos de Intervenção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-tiposIntervencao',
      name: 'listar-tiposIntervencao',
      component: () => import('@/views/tabelas/TiposIntervencao/Listar.vue'),
      meta: {
        pageTitle: 'Tipos de Intervenção',
        breadcrumb: [
          {
            text: 'Tipos de Intervenção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-tiposProcesso',
      name: 'adicionar-tiposProcesso',
      component: () => import('@/views/tabelas/TiposProcesso/Adicionar.vue'),
      meta: {
        pageTitle: 'Tipos de Processo',
        breadcrumb: [
          {
            text: 'Tipos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tiposProcesso/:id',
      name: 'editar-tiposProcesso-id',
      component: () => import('@/views/tabelas/TiposProcesso/Editar.vue'),
      meta: {
        pageTitle: 'Tipos de Processo',
        breadcrumb: [
          {
            text: 'Tipos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-tiposProcesso',
      name: 'editar-tiposProcesso',
      component: () => import('@/views/tabelas/TiposProcesso/Editar.vue'),
      meta: {
        pageTitle: 'Tipos de Processo',
        breadcrumb: [
          {
            text: 'Tipos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-tiposProcesso',
      name: 'listar-tiposProcesso',
      component: () => import('@/views/tabelas/TiposProcesso/Listar.vue'),
      meta: {
        pageTitle: 'Tipos de Processo',
        breadcrumb: [
          {
            text: 'Tipos de Processo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-estado',
      name: 'adicionar-estado',
      component: () => import('@/views/tabelas/Estados/Adicionar.vue'),
      meta: {
        pageTitle: 'Estados',
        breadcrumb: [
          {
            text: 'Estados',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-estado/:id',
      name: 'editar-estado-id',
      component: () => import('@/views/tabelas/Estados/Editar.vue'),
      meta: {
        pageTitle: 'Estados',
        breadcrumb: [
          {
            text: 'Estados',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-estado',
      name: 'editar-estado',
      component: () => import('@/views/tabelas/Estados/Editar.vue'),
      meta: {
        pageTitle: 'Estados',
        breadcrumb: [
          {
            text: 'Estados',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-estado',
      name: 'listar-estado',
      component: () => import('@/views/tabelas/Estados/Listar.vue'),
      meta: {
        pageTitle: 'Estados',
        breadcrumb: [
          {
            text: 'Estados',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-prioridade',
      name: 'adicionar-prioridade',
      component: () => import('@/views/tabelas/Prioridades/Adicionar.vue'),
      meta: {
        pageTitle: 'Prioridades',
        breadcrumb: [
          {
            text: 'Prioridades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-prioridade/:id',
      name: 'editar-prioridade-id',
      component: () => import('@/views/tabelas/Prioridades/Editar.vue'),
      meta: {
        pageTitle: 'Prioridades',
        breadcrumb: [
          {
            text: 'Prioridades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-prioridade',
      name: 'editar-prioridade',
      component: () => import('@/views/tabelas/Prioridades/Editar.vue'),
      meta: {
        pageTitle: 'Prioridades',
        breadcrumb: [
          {
            text: 'Prioridades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-prioridade',
      name: 'listar-prioridade',
      component: () => import('@/views/tabelas/Prioridades/Listar.vue'),
      meta: {
        pageTitle: 'Prioridades',
        breadcrumb: [
          {
            text: 'Prioridades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-vendedor',
      name: 'adicionar-vendedor',
      component: () => import('@/views/tabelas/Vendedores/Adicionar.vue'),
      meta: {
        pageTitle: 'Vendedores',
        breadcrumb: [
          {
            text: 'Vendedores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-vendedor/:id',
      name: 'editar-vendedor-id',
      component: () => import('@/views/tabelas/Vendedores/Editar.vue'),
      meta: {
        pageTitle: 'Vendedores',
        breadcrumb: [
          {
            text: 'Vendedores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-vendedor',
      name: 'editar-vendedor',
      component: () => import('@/views/tabelas/Vendedores/Editar.vue'),
      meta: {
        pageTitle: 'Vendedores',
        breadcrumb: [
          {
            text: 'Vendedores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-vendedor',
      name: 'listar-vendedor',
      component: () => import('@/views/tabelas/Vendedores/Listar.vue'),
      meta: {
        pageTitle: 'Vendedores',
        breadcrumb: [
          {
            text: 'Vendedores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-motivo',
      name: 'adicionar-motivo',
      component: () => import('@/views/tabelas/Motivos/Adicionar.vue'),
      meta: {
        pageTitle: 'Motivos',
        breadcrumb: [
          {
            text: 'Motivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-motivo/:id',
      name: 'editar-motivo-id',
      component: () => import('@/views/tabelas/Motivos/Editar.vue'),
      meta: {
        pageTitle: 'Motivos',
        breadcrumb: [
          {
            text: 'Motivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-motivo',
      name: 'editar-motivo',
      component: () => import('@/views/tabelas/Motivos/Editar.vue'),
      meta: {
        pageTitle: 'Motivos',
        breadcrumb: [
          {
            text: 'Motivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-motivo',
      name: 'listar-motivo',
      component: () => import('@/views/tabelas/Motivos/Listar.vue'),
      meta: {
        pageTitle: 'Motivos',
        breadcrumb: [
          {
            text: 'Motivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-pais',
      name: 'adicionar-pais',
      component: () => import('@/views/tabelas/Paises/Adicionar.vue'),
      meta: {
        pageTitle: 'Países',
        breadcrumb: [
          {
            text: 'Países',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-pais/:id',
      name: 'editar-pais-id',
      component: () => import('@/views/tabelas/Paises/Editar.vue'),
      meta: {
        pageTitle: 'Países',
        breadcrumb: [
          {
            text: 'Países',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-pais',
      name: 'editar-pais',
      component: () => import('@/views/tabelas/Paises/Editar.vue'),
      meta: {
        pageTitle: 'Países',
        breadcrumb: [
          {
            text: 'Países',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-pais',
      name: 'listar-pais',
      component: () => import('@/views/tabelas/Paises/Listar.vue'),
      meta: {
        pageTitle: 'Países',
        breadcrumb: [
          {
            text: 'Países',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-moeda',
      name: 'adicionar-moeda',
      component: () => import('@/views/tabelas/Moedas/Adicionar.vue'),
      meta: {
        pageTitle: 'Moedas',
        breadcrumb: [
          {
            text: 'Moedas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-moeda/:id',
      name: 'editar-moeda-id',
      component: () => import('@/views/tabelas/Moedas/Editar.vue'),
      meta: {
        pageTitle: 'Moedas',
        breadcrumb: [
          {
            text: 'Moedas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-moeda',
      name: 'editar-moeda',
      component: () => import('@/views/tabelas/Moedas/Editar.vue'),
      meta: {
        pageTitle: 'Moedas',
        breadcrumb: [
          {
            text: 'Moedas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-moeda',
      name: 'listar-moeda',
      component: () => import('@/views/tabelas/Moedas/Listar.vue'),
      meta: {
        pageTitle: 'Moedas',
        breadcrumb: [
          {
            text: 'Moedas',
            active: true,
          },
        ],
      },
    },

    {
      path: '/adicionar-perfil',
      name: 'adicionar-perfil',
      component: () => import('@/views/tabelas/Perfil/AdicionarPerfil.vue'),
      meta: {
        pageTitle: 'Perfis',
        breadcrumb: [
          {
            text: 'Perfis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-perfil/:id',
      name: 'editar-perfil-id',
      component: () => import('@/views/tabelas/Perfil/EditarPerfil.vue'),
      meta: {
        pageTitle: 'Perfis',
        breadcrumb: [
          {
            text: 'Perfis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-perfil',
      name: 'editar-perfil',
      component: () => import('@/views/tabelas/Perfil/EditarPerfil.vue'),
      meta: {
        pageTitle: 'Perfis',
        breadcrumb: [
          {
            text: 'Perfis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/listar-perfil',
      name: 'listar-perfil',
      component: () => import('@/views/tabelas/Perfil/ListarPerfil.vue'),
      meta: {
        pageTitle: 'Perfis',
        breadcrumb: [
          {
            text: 'Perfis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/adicionar-utilizador',
      name: 'adicionar-utilizador',
      component: () => import('@/views/tabelas/Utilizadores/Adicionar/Utilizadores.vue'),
      meta: {
        pageTitle: 'Utilizadores',
        breadcrumb: [
          {
            text: 'Utilizadores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-utilizador/:id',
      name: 'editar-utilizador-id',
      component: () => import('@/views/tabelas/Utilizadores/Editar/Utilizadores.vue'),
      meta: {
        pageTitle: 'Utilizadores',
        breadcrumb: [
          {
            text: 'Utilizadores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-utilizador',
      name: 'editar-utilizador',
      component: () => import('@/views/tabelas/Utilizadores/Editar/Utilizadores.vue'),
      meta: {
        pageTitle: 'Utilizadores',
        breadcrumb: [
          {
            text: 'Utilizadores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
